import React, { useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import useToast from 'custom-hooks/useToast';
import { useAsync } from 'react-async';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { checkPasswordToken, submitPassword } from 'services/auth';
import { toastConstants, toastTypes } from 'constants/toastConstants';

function SetPasswordPage() {
    const history = useHistory();
    const { token } = useParams();
    const { showToast } = useToast();
    const { data, error, isPending } = useAsync({
        promiseFn: checkPasswordToken,
        token
    });
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [isError, setIsError] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        if (shouldSubmitButtonBeDisabled()) {
            setIsError(true);
            return;
        }
        try {
            setIsLoading(true);
            await submitPassword({ token, password });
            showToast(toastTypes.success, toastConstants.setPassword.success);
            history.push('/login');
        } catch {
            setIsLoading(false);
            setIsError(true);
        }
    };

    const shouldSubmitButtonBeDisabled = () => {
        if (password?.length >= 6 && confirmPassword?.length >= 6) {
            if (password === confirmPassword) {
                return false;
            }
            return true;
        }
        return true;
    };

    if (error) {
        showToast(toastTypes.danger, toastConstants.setPassword.error);
        return <Redirect to="/login" />;
    } else if (data) {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col sm={6}>
                        <StyledCard>
                            <Card.Header>Създаване на парола</Card.Header>
                            <Card.Body>
                                {isError && (
                                    <Alert variant="danger">
                                        Уверете се, че паролата въведена в двете полета е една и
                                        съща и е над 6 символа.
                                    </Alert>
                                )}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="password">
                                        <Form.Label>Парола:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Парола"
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <Form.Text id="passwordHelpBlock" muted>
                                            Паролата трябва да е минимум 6 символа.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="confirm-password">
                                        <Form.Label>Въведете отново паролата:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Потвърдете паролата"
                                            onChange={e => setConfirmPassword(e.target.value)}
                                        />
                                        <Form.Text id="passwordHelpBlock" muted>
                                            Паролите трябва да съвпадат.
                                        </Form.Text>
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        block
                                        disabled={shouldSubmitButtonBeDisabled()}
                                    >
                                        {isLoading ? (
                                            <Spinner animation="border" size="sm" />
                                        ) : (
                                            'Запази паролата'
                                        )}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </StyledCard>
                    </Col>
                </Row>
            </Container>
        );
    }
    return null;
}

const StyledCard = styled(Card)`
    margin-top: 100px;
`;
export default SetPasswordPage;
