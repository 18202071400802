import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import SingleOrderPage from 'components/pages/partner/SingleOrderPage';
import PrivateRoute from 'components/routes/PrivateRoute';
const DashboardPage = React.lazy(() => import('components/pages/admin/DashboardPage'));

const InquiriesPage = React.lazy(() => import('components/pages/partner/InquiriesPage'));

const SingleInquiryPage = React.lazy(() => import('components/pages/partner/SingleInquiryPage'));

const OrdersPage = React.lazy(() => import('components/pages/partner/OrdersPage'));

const ProfilePage = React.lazy(() => import('components/pages/partner/ProfilePage'));

function PartnerRoutes() {
    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                <PrivateRoute path="/" exact>
                    <DashboardPage />
                </PrivateRoute>
                <PrivateRoute path="/inquiries" exact>
                    <InquiriesPage />
                </PrivateRoute>
                <PrivateRoute path="/inquiries/:id" exact>
                    <SingleInquiryPage />
                </PrivateRoute>
                <PrivateRoute path="/orders" exact>
                    <OrdersPage />
                </PrivateRoute>
                <PrivateRoute path="/orders/:id" exact>
                    <SingleOrderPage />
                </PrivateRoute>
                <PrivateRoute path="/profile" exact>
                    <ProfilePage />
                </PrivateRoute>
                <PrivateRoute>
                    <Redirect to="/" />
                </PrivateRoute>
            </Switch>
        </Suspense>
    );
}

export default PartnerRoutes;
