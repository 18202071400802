import { useContext, useState } from 'react';
import { SHOW_CONFIRM, HIDE_CONFIRM } from 'store/confirm/constants';
import ConfirmContext from 'context/ConfirmContext';
let resolve;

function useConfirm() {
    const [confirmState, dispatch] = useContext(ConfirmContext);
    const onConfirm = () => {
        closeConfirm();
        resolve(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolve(false);
    };

    const confirm = text => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text
            }
        });
        return new Promise((res, rej) => {
            resolve = res;
        });
    };

    const closeConfirm = () => {
        dispatch({
            type: HIDE_CONFIRM
        });
    };

    return { confirm, onConfirm, onCancel, confirmState };
}

export default useConfirm;
