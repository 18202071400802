import { SHOW_TOAST, HIDE_TOAST } from 'store/toast/constants';

export const initialState = {
    show: false,
    message: '',
    variant: 'default'
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_TOAST:
            return {
                show: true,
                message: action.payload.message,
                variant: action.payload.variant
            };
        case HIDE_TOAST:
            return initialState;
        default:
            return initialState;
    }
};
