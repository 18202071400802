import { useContext } from 'react';
import ToastContext from 'react-bootstrap/cjs/ToastContext';
import { HIDE_TOAST, SHOW_TOAST } from 'store/toast/constants';

function useToast() {
    const [toastState, dispatch] = useContext(ToastContext);

    const showToast = (variant, message) => {
        dispatch({
            type: SHOW_TOAST,
            payload: {
                variant,
                message
            }
        });
    };

    const closeToast = () => {
        dispatch({
            type: HIDE_TOAST
        });
    };

    return { showToast, closeToast, toastState };
}

export default useToast;
