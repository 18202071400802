import axios from 'axios';

// ADMIN
export const getOrders = queryParamsArr => {
    let params = null;
    if (queryParamsArr.length && queryParamsArr.length > 0) {
        [params] = queryParamsArr;
    }
    return axios.get('/api/admin/orders', { params });
};

export const getOrder = ({ id }) => {
    return axios.get(`/api/admin/orders/${id}`);
};

// PARTNER
export const getPartnerOrders = queryParamsArr => {
    let params = null;
    if (queryParamsArr.length && queryParamsArr.length > 0) {
        [params] = queryParamsArr;
    }
    return axios.get('/api/partner/orders', { params });
};

export const getPartnerOrder = ({ id }) => {
    return axios.get(`/api/partner/orders/${id}`);
};
