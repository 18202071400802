import { Spinner } from 'react-bootstrap';
import React from 'react';

const CustomSpinner = ({ className }) => (
    <div className={`p-5 d-flex justify-content-center ${className || ''}`}>
        <Spinner animation="border" variant="secondary" />
    </div>
);

export default CustomSpinner;
