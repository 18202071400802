import { SET_USER, RESET_USER, SET_USER_UNAUTHENTICATED } from './constants';

export const initialState = {
    username: null,
    roles: [],
    authChecked: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                username: action.payload.username,
                roles: action.payload.roles,
                authChecked: true
            };
        case SET_USER_UNAUTHENTICATED:
            return {
                ...initialState,
                authChecked: true
            };
        case RESET_USER:
            return {
                ...initialState,
                authChecked: true
            };
        default:
            return initialState;
    }
};
