import React from 'react';
import { Card } from 'react-bootstrap';

function PageWrapper({ header, children, className }) {
    return (
        <Card className={className}>
            <Card.Header>{header}</Card.Header>
            <Card.Body>{children}</Card.Body>
        </Card>
    );
}

export default PageWrapper;
