import { useContext, useEffect, useState } from 'react';
import { RESET_USER, SET_USER, SET_USER_UNAUTHENTICATED } from 'store/auth/constants';
import AuthContext from 'context/AuthContext';
import { checkIsAuthenticated, removeToken } from 'services/auth';

function useAuthState() {
    const [state, dispatch] = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const setUser = ({ username, roles }) => {
        dispatch({
            type: SET_USER,
            payload: {
                username,
                roles
            }
        });
    };

    const resetUser = payload => {
        dispatch({
            type: RESET_USER
        });
    };

    const checkAuthStatus = () => {
        const auth = checkIsAuthenticated();
        if (auth && auth.username && auth.roles) {
            dispatch({
                type: SET_USER,
                payload: {
                    username: auth.username,
                    roles: auth.roles
                }
            });
        } else {
            removeToken();
            dispatch({ type: SET_USER_UNAUTHENTICATED });
        }
    };
    useEffect(() => {
        if (state.roles) {
            const [role] = state.roles;
            role && setUserRole(role);
        }
    }, [state.roles]);

    useEffect(() => {
        if (!!state.username && state.authChecked) {
            setIsAuthenticated(true);
        } else if (!state.username && state.authChecked) {
            setIsAuthenticated(false);
        }
    }, [state]);

    return { setUser, checkAuthStatus, resetUser, userRole, isAuthenticated, ...state };
}

export default useAuthState;
