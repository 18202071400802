import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { removeToken } from 'services/auth';
import useAuthState from 'custom-hooks/useAuthState';

function Header() {
    const { username, resetUser } = useAuthState();
    const history = useHistory();

    const onLogout = () => {
        removeToken();
        resetUser();
        history.push('/login');
    };

    return (
        <Navbar bg="light">
            <Container>
                <Navbar.Brand>
                    <Link to="/">Fixi</Link>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {username && (
                            <Button variant="link" onClick={onLogout}>
                                Logout
                            </Button>
                        )}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
