import { Toast } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import useToast from 'custom-hooks/useToast';
import { toastTypes } from 'constants/toastConstants';

const CustomToast = () => {
    const { toastState, closeToast } = useToast();

    const getColorForVariant = toastVariant => {
        switch (toastVariant) {
            case toastTypes.success:
                return {
                    textColor: '#155724',
                    backgroundColor: '#d4edda'
                };
            case toastTypes.danger:
                return {
                    textColor: '#721c24',
                    backgroundColor: '#f8d7da'
                };
            default:
                return {
                    textColor: 'initial',
                    backgroundColor: 'initial'
                };
        }
    };
    return (
        <StyledToast
            show={toastState.show}
            onClose={closeToast}
            colors={getColorForVariant(toastState.variant)}
            autohide
            delay={5000}
        >
            <Toast.Header />
            <Toast.Body>{toastState.message}</Toast.Body>
        </StyledToast>
    );
};

const StyledToast = styled(Toast)`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999999;
    padding: 8px 0 8px 8px;

    &.show {
        display: flex;
        flex-direction: row-reverse;
        background: ${props => props.colors.backgroundColor};
        color: ${props => props.colors.textColor};
    }

    .toast-header {
        padding-top: 0;
        padding-bottom: 0;
        justify-content: flex-end;
        border-bottom: 0;
        background: none;

        button.close {
            font-size: 1.3rem;
        }
    }
`;

export default CustomToast;
