import React from 'react';
import styled from 'styled-components';

const KeyValueRow = ({ property, value }) => {
    if (value) {
        return (
            <StyledDataRow>
                <span className="property">{property}</span>
                <span className="value">{value}</span>
            </StyledDataRow>
        );
    } else {
        return null;
    }
};

const StyledDataRow = styled.div`
    margin-bottom: 10px;

    .property {
        font-weight: normal;
        width: 70px;
        margin-right: 12px;
    }
    .value {
        font-weight: 500;
    }
`;

export default KeyValueRow;
