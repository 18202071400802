import React from 'react';
import PropTypes from 'prop-types';

function ServicesPriceTable({ services }) {
    return (
        <table className="table mt-2 table-bordered">
            <thead>
                <tr>
                    <th>Услуга</th>
                    <th>Цена, лв</th>
                </tr>
            </thead>
            <tbody>
                {services.map(service => (
                    <tr key={service?.service.name}>
                        <td>{service?.service?.name || ''}</td>
                        <td>{service?.price || ''}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

ServicesPriceTable.propTypes = {
    services: PropTypes.array.isRequired
};

export default ServicesPriceTable;
