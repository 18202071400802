import axios from 'axios';
import { attachBearerTokenToEachRequestInterceptor, detachTokenToEachRequest } from 'config/axios';
const jwt_decode = require('jwt-decode');

const getTokenInfo = token => {
    try {
        const { roles, username } = jwt_decode(token);
        return { roles, username };
    } catch {
        return null;
    }
};

export const checkIsAuthenticated = () => {
    const token = localStorage.getItem('access-token');
    if (token) {
        attachBearerTokenToEachRequestInterceptor();
        return getTokenInfo(token);
    }
    return null;
};

export const login = async ({ email, password }) => {
    const { data } = await axios.post('/api/login', { email, password });
    const token = data.accessToken;
    const refreshToken = data.refreshToken;
    if (token) {
        localStorage.setItem('access-token', token);
        attachBearerTokenToEachRequestInterceptor();
        localStorage.setItem('refresh-token', refreshToken);
        return getTokenInfo(token);
    }
};

export const removeToken = () => {
    detachTokenToEachRequest();
    localStorage.removeItem('access-token');
};

export const checkPasswordToken = async ({ token }) => {
    return axios.get('/api/auth/verify-token/' + token);
};

export const submitPassword = async ({ token, password }) => {
    return axios.post('/api/auth/set-password/' + token, {
        user_set_password: { password }
    });
};
