export const toastConstants = {
    login: {
        success: 'Успешно влизане в системата.',
        error: 'Влизането в системата е неуспешно. Проверете дали имейла и паролата са валидни.'
    },
    setPassword: {
        success: 'Паролата беше запазена успешно.',
        error: 'Кодът за въвеждане на парола е невалиден.'
    },
    partners: {
        create: {
            success: 'Партньорът беше запазен успешно.',
            error: 'Нещо се обърка, партньорът не беше запазен успешно.'
        },
        edit: {
            success: 'Партньорът беше редактиран успешно.',
            error: 'Нещо се обърка, партньорът не беше редактиран успешно.'
        },
        delete: {
            success: 'Партньорът беше изтрит успешно.',
            error: 'Нещо се обърка, партньорът не беше изтрит.'
        },
        show: {
            error: 'Нещо се обърка, партньорите не бяха заредени успешно.'
        }
    },
    services: {
        create: {
            success: 'Услугата беше създадена успешно.',
            error: 'Нещо се обърка, услугата не беше запомнена.'
        },
        edit: {
            success: 'Името на услугата беше редактирано успешно.',
            error: 'Нещо се обърка, услугата не беше редактирана.'
        },
        assign: {
            success: 'Услугите бяха добавени към партньора успешно',
            error: 'Нещо се обърка, услугите не бяха добавени успешно.'
        },
        delete: {
            success: 'Услугата беше изтрита успешно.',
            error: 'Нещо се обърка, услугата не беше изтрита.'
        }
    },
    variations: {
        create: {
            success: 'Подуслугата беше създадена успешно.',
            error: 'Нещо се обърка, подуслугата не беше запазена.'
        },
        edit: {
            success: 'Името на подуслугата беше редактирано успешно.',
            error: 'Нещо се обърка, подуслугата не беше редактирана.'
        },
        delete: {
            success: 'Подуслугата беше изтрита успешно.',
            error: 'Нещо се обърка, вариантът не беше изтрит.'
        }
    },
    user: {
        create: {
            success: 'Потребителят беше създаден успешно.',
            error: 'Нещо се обърка,потребителят не беше запазен успешно.'
        },
        show: {
            error: 'Нещо се обърка,информацията за потребителя не беше заредена успешно.'
        }
    },
    images: {
        create: {
            success: 'Изображението беше добавено успешно.',
            error: 'Нещо се обърка, изображението не беше запазено.'
        },
        delete: {
            success: 'Изображението беше изтрито успешно.',
            error: 'Нещо се обърка, изображението не беше изтрито.'
        }
    },
    offers: {
        create: {
            success: 'Офертата беше направена успешно.',
            error: 'Нещо се обърка, офертата не беше направена успешно.'
        }
    }
};

export const toastTypes = {
    success: 'success',
    danger: 'danger',
    primary: 'primary'
};
