import { Card } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
    margin-top: 1rem;
`;

function ListCard({ children, ...props }) {
    return (
        <StyledCard {...props}>
            <Card.Body>{children}</Card.Body>
        </StyledCard>
    );
}

export default ListCard;
