import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import CustomSpinner from 'components/utils/CustomSpinner';
import PageWrapper from 'components/layout/PageWrapper';
import { Col, Row } from 'react-bootstrap';
import KeyValueRow from 'components/utils/KeyValueRow';
import { getPartnerOrder } from 'services/orders';
import { cutDateFromString } from 'services/utils';
import VehicleData from 'components/partials/VehicleData';
import ListCard from 'components/utils/ListCard';
import { formatDeliveryOption, formatNameAndPhone } from 'services/formatters';
import ServicesPriceTable from 'components/utils/SericesPriceTable';

function SingleOrderPage() {
    const { id } = useParams();
    const [offerData, setOfferData] = useState(null);
    const [inquiryData, setInquiryData] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const { data, error, isPending } = useAsync({
        promiseFn: getPartnerOrder,
        id: id
    });

    useEffect(() => {
        if (data && data.data) {
            setOfferData(data.data.offer);
            setInquiryData(data.data?.offer?.inquiry);
            setOrderData(data.data);
        }
    }, [data]);

    const formatDeliveryOptionValue = () => {
        const formattedDate = inquiryData.deliveryDate
            ? cutDateFromString(inquiryData.deliveryDate)
            : '';
        return formatDeliveryOption(inquiryData.deliveryOption, formattedDate);
    };

    return (
        <PageWrapper
            header={
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        Преглед на поръчка {offerData?.hash || ''}
                    </div>
                </div>
            }
        >
            {isPending && <CustomSpinner />}
            {!isPending && !error && orderData && (
                <Row>
                    <Col md={12} className="mb-3">
                        <div>
                            <h5 className="mb-3">Информация за поръчката:</h5>
                            <KeyValueRow property="Id:" value={orderData.hash} />
                            <KeyValueRow
                                property="Дата на създаване:"
                                value={cutDateFromString(orderData.createdAt)}
                            />
                            <KeyValueRow
                                property="Дата на промяна:"
                                value={cutDateFromString(orderData.updatedAt)}
                            />
                            <KeyValueRow
                                property="Врата до врата:"
                                value={orderData.hasDoorToDoor ? 'Да' : 'Не'}
                            />
                            <KeyValueRow property="Статус:" value={orderData.status} />
                            <KeyValueRow property="Съобщение:" value={orderData.message} />
                            <KeyValueRow property="Рейтинг:" value={orderData.rating} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <h5 className="mb-3">Запитване:</h5>
                        <ListCard>
                            <KeyValueRow
                                property="Дата:"
                                value={cutDateFromString(inquiryData.createdAt)}
                            />
                            <KeyValueRow
                                property="Услуги:"
                                value={
                                    inquiryData.services.length && (
                                        <ul className="pt-2">
                                            {inquiryData.services.map(service => (
                                                <li key={service.name}>{service.name}</li>
                                            ))}
                                        </ul>
                                    )
                                }
                            />
                            {inquiryData?.contact?.firstName && inquiryData?.contact?.lastName && (
                                <KeyValueRow
                                    property="Контакт:"
                                    value={`${formatNameAndPhone(inquiryData?.contact)}`}
                                />
                            )}
                            <KeyValueRow property="Град:" value={inquiryData.city} />
                            <KeyValueRow property="Бележка:" value={inquiryData.note} />
                            <KeyValueRow
                                property="Изпълнение:"
                                value={formatDeliveryOptionValue()}
                            />
                            {inquiryData?.vehicle && <VehicleData vehicle={inquiryData.vehicle} />}
                        </ListCard>
                    </Col>
                    <Col md={6}>
                        <h5 className="mb-3">Оферта:</h5>
                        <ListCard>
                            <KeyValueRow
                                property="Дата на създаване:"
                                value={cutDateFromString(offerData.createdAt)}
                            />
                            <KeyValueRow
                                property="Дата на приемане:"
                                value={cutDateFromString(offerData.acceptedAt)}
                            />
                            <KeyValueRow
                                property="Услуги:"
                                value={
                                    offerData.offeredServices && (
                                        <div className="pt-2">
                                            {offerData?.offeredServices && (
                                                <ServicesPriceTable
                                                    services={offerData?.offeredServices?.filter(
                                                        service => !service.isExternal
                                                    )}
                                                />
                                            )}
                                        </div>
                                    )
                                }
                            />
                            <KeyValueRow
                                property="Сервиз:"
                                value={
                                    offerData.shop && (
                                        <div className="pl-4 pt-2">
                                            <KeyValueRow
                                                property="Име:"
                                                value={offerData?.shop?.name}
                                            />
                                            <KeyValueRow
                                                property="Описание:"
                                                value={offerData?.shop?.description}
                                            />
                                            <KeyValueRow
                                                property="Град:"
                                                value={offerData?.shop?.city}
                                            />
                                            <KeyValueRow
                                                property="Адрес:"
                                                value={offerData?.shop?.address}
                                            />
                                            <KeyValueRow
                                                property="Телефон:"
                                                value={offerData?.shop?.phone}
                                            />
                                            <KeyValueRow
                                                property="Рейтинг:"
                                                value={offerData?.shop?.rating}
                                            />
                                        </div>
                                    )
                                }
                            />
                        </ListCard>
                    </Col>
                </Row>
            )}
        </PageWrapper>
    );
}

export default SingleOrderPage;
