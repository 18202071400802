import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useAuthState from 'custom-hooks/useAuthState';

function PrivateRoute({ children, ...rest }) {
    const [authStatusReady, setAuthStatusReady] = useState(false);
    const { isAuthenticated } = useAuthState();

    useEffect(() => {
        setAuthStatusReady(true);
    }, [isAuthenticated]);

    return (
        authStatusReady && (
            <Route
                {...rest}
                render={({ location }) =>
                    isAuthenticated ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location }
                            }}
                        />
                    )
                }
            />
        )
    );
}

export default PrivateRoute;
