import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style.scss';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import 'config/axios';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from 'context/AppProvider';
import CustomToast from 'components/utils/CustomToast';

ReactDOM.render(
    <AppProvider>
        <Router>
            <App />
        </Router>
        <CustomToast />
    </AppProvider>,
    document.getElementById('root')
);
