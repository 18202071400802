import React from 'react';
import KeyValueRow from 'components/utils/KeyValueRow';
import PropTypes from 'prop-types';

function VehicleData({ vehicle, excludeLicencePlate }) {
    const formatVehicleTitle = vehicle => {
        if (vehicle) {
            return `${vehicle.make || ''} ${vehicle.model || ''} ${vehicle.modification ||
                ''} ${vehicle.year || ''}`;
        } else {
            return null;
        }
    };

    return (
        <>
            <KeyValueRow property="Кола:" value={formatVehicleTitle(vehicle)} />
            <KeyValueRow property="Двигател:" value={vehicle.engineType} />
            <KeyValueRow property="Гориво:" value={vehicle.fuelType} />
            <KeyValueRow property="Размер на гуми:" value={vehicle.wheelSize} />
            {!excludeLicencePlate && (
                <KeyValueRow property="Рег. номер:" value={vehicle.licensePlate} />
            )}
            <KeyValueRow property="Шофьорска книжка до:" value={vehicle.drivingLicenseExpDate} />
            <KeyValueRow property="Винетка до:" value={vehicle.vignetteExpDate} />
            <KeyValueRow property="ГТП до:" value={vehicle.annualTechControlExpDate} />
            <KeyValueRow property="Застраховка до:" value={vehicle.insuranceExpDate} />
        </>
    );
}

VehicleData.propTypes = {
    vehicle: PropTypes.object.isRequired,
    excludeLicencePlate: PropTypes.bool
};

export default VehicleData;
