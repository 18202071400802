import Header from 'components/layout/Header';
import LoginPage from 'components/pages/LoginPage';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthContainer from 'components/layout/AuthContainer';
import PrivateRoute from 'components/routes/PrivateRoute';
import GuestRoute from 'components/routes/GuestRoute';
import SetPasswordPage from 'components/pages/SetPasswordPage';

function App() {
    return (
        <Router>
            <Header />
            <Switch>
                <GuestRoute path="/login" exact>
                    <LoginPage />
                </GuestRoute>
                <GuestRoute path="/set-password/:token" exact>
                    <SetPasswordPage />
                </GuestRoute>
                <PrivateRoute path="/">
                    <AuthContainer />
                </PrivateRoute>
            </Switch>
        </Router>
    );
}

export default App;
