import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import useConfirm from 'custom-hooks/useConfirm';
const ConfirmModal = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();
    const mount = document.getElementById('portal');
    const el = confirmState.show ? (
        <PortalOverlay>
            <div className="confirm-dialog">
                <p>{confirmState?.text && confirmState.text}</p>
                <div className="d-flex justify-content-end mt-3">
                    <Button className="mr-2" onClick={onConfirm}>
                        Да
                    </Button>
                    <Button variant="outline-primary" onClick={onCancel}>
                        Отказ
                    </Button>
                </div>
            </div>
        </PortalOverlay>
    ) : null;
    return createPortal(el, mount);
};

const PortalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .confirm-dialog {
        z-index: 1000000000000111;
        padding: 16px;
        border-radius: 5px;
        background-color: white;
        width: 400px;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
    }
`;
export default ConfirmModal;
