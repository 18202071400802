import React, { useEffect, useReducer, useState } from 'react';
import * as authStore from 'store/auth';
import * as toastStore from 'store/toast';
import * as confirmStore from 'store/confirm';
import { checkIsAuthenticated, removeToken } from 'services/auth';
import { SET_USER, SET_USER_UNAUTHENTICATED } from 'store/auth/constants';
import AuthContext from 'context/AuthContext';
import ToastContext from 'react-bootstrap/cjs/ToastContext';
import { refreshAccessTokenInterceptor } from 'config/axios';
import ConfirmModal from 'components/modals/ConfirmModal';
import ConfirmContext from 'context/ConfirmContext';

const AppProvider = ({ children }) => {
    const [authState, authDispatch] = useReducer(authStore.reducer, authStore.initialState);
    const [toastState, toastDispatch] = useReducer(toastStore.reducer, toastStore.initialState);
    const [confirmState, confirmDispatch] = useReducer(
        confirmStore.reducer,
        confirmStore.initialState
    );
    const [authChecked, setAuthChecked] = useState(false);

    const checkIfUserIsAuthenticated = () => {
        const auth = checkIsAuthenticated();
        if (auth && auth.username && auth.roles) {
            authDispatch({
                type: SET_USER,
                payload: {
                    username: auth.username,
                    roles: auth.roles
                }
            });
        } else {
            removeToken();
            authDispatch({ type: SET_USER_UNAUTHENTICATED });
        }
    };

    useEffect(() => {
        refreshAccessTokenInterceptor();
        checkIfUserIsAuthenticated();
        setAuthChecked(true);
    }, []);

    return (
        <AuthContext.Provider value={[authState, authDispatch]}>
            <ToastContext.Provider value={[toastState, toastDispatch]}>
                <ConfirmContext.Provider value={[confirmState, confirmDispatch]}>
                    {authChecked && children}
                    <ConfirmModal />
                </ConfirmContext.Provider>
            </ToastContext.Provider>
        </AuthContext.Provider>
    );
};

export default AppProvider;
