import { getConfig } from 'services/config';

export const getIdFromResponseHeader = res => {
    if (res.headers.location) {
        const locationStringToArray = res.headers.location.split('/');
        return locationStringToArray[locationStringToArray.length - 1];
    }
    return null;
};

export const getFullUrlForImgFile = fileName => {
    const baseURL = getConfig('BASE_URL');
    return baseURL + '/uploads/' + fileName;
};

export const getIdAndFileURLFromUploadedImage = res => {
    if (res.headers.location) {
        const locationStringToArray = res.headers.location.split('/');
        const fileName = locationStringToArray[locationStringToArray.length - 1];
        const id = locationStringToArray[locationStringToArray.length - 2];
        const fileURL = getFullUrlForImgFile(fileName);
        return { id, fileURL };
    }
};

export const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

const addLeadingZeroes = n => {
    return String('00' + n).slice(0, 10);
};

export const cutDateFromString = date => {
    if (date) {
        return date.substring(0, 10);
    }
    return null;
};

export const getFormattedDate = dateString => {
    const date = new Date(dateString);
    const formattedDate = addLeadingZeroes(date.getDate());
    const formattedMonth = addLeadingZeroes(date.getMonth());
    return `${formattedDate}.${formattedMonth}.${date.getFullYear()}`;
};

export const generateRandomString = () =>
    Math.random()
        .toString(36)
        .substring(2, 15) +
    Math.random()
        .toString(36)
        .substring(2, 15);

export const getFullName = ({ firstName, lastName }) => {
    if (firstName && lastName) {
        return ` ${firstName} ${lastName}`;
    } else {
        return '';
    }
};

export const getCurrentDate = () => {
    return new Date().toISOString().substring(0, 10);
};

export const isObject = variable => {
    return Object.prototype.toString.call(variable) === '[object Object]';
};

export const transformQueryParamsToObject = string => {
    return JSON.parse(
        '{"' +
            decodeURI(string)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
    );
};

export const transformObjectToQueryParams = object => {
    return Object.keys(object)
        .map(key => key + '=' + object[key])
        .join('&');
};
