import axios from 'axios';
import { getConfig } from 'services/config';

axios.defaults.baseURL = getConfig('BASE_URL');
let attachBearerToEachReqInterceptor;

export const attachBearerTokenToEachRequestInterceptor = () => {
    attachBearerToEachReqInterceptor = axios.interceptors.request.use(config => {
        const token = localStorage.getItem('access-token');
        if (!config.url.includes('login') && !config.url.includes('refresh-token') && token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    });
};

export const detachTokenToEachRequest = () => {
    axios.interceptors.request.eject(attachBearerToEachReqInterceptor);
};

export const refreshAccessTokenInterceptor = () => {
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            // Reject promise if usual error
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }
            axios.interceptors.response.eject(interceptor);

            return axios
                .post('/api/auth/refresh-token', {
                    token: localStorage.getItem('refresh-token')
                })
                .then(response => {
                    const { accessToken, refreshToken } = response.data;
                    const config = error.response.config;
                    if (accessToken && refreshToken) {
                        localStorage.setItem('access-token', accessToken);
                        localStorage.setItem('refresh-token', refreshToken);
                    }
                    return axios(config);
                })
                .catch(error => {
                    localStorage.removeItem('access-token');
                    localStorage.removeItem('refresh-token');
                    window.location.reload();
                    return Promise.reject(error);
                })
                .finally(refreshAccessTokenInterceptor);
        }
    );
};
