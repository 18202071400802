import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ROLE_ADMIN, ROLE_PARTNER } from 'constants/userRolesConstants';
import SidebarNavLink from 'components/utils/SidebarNavLink';
import { useLocation } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomSpinner from 'components/utils/CustomSpinner';
import AdminRoutes from 'components/routes/AdminRoutes';
import useAuthState from 'custom-hooks/useAuthState';
import PartnerRoutes from 'components/routes/PartnerRoutes';

const AuthContainer = () => {
    const { checkAuthStatus, userRole } = useAuthState();
    const location = useLocation();

    useEffect(() => {
        checkAuthStatus();
    }, [location.pathname]);
    return (
        <Container>
            <Row className="justify-content-center mt-5 pb-5">
                <Col md={2} className="pl-0">
                    {userRole === ROLE_PARTNER ? (
                        <ListGroup>
                            <SidebarNavLink to="/" activeOnlyWhenExact={true}>
                                Табло
                            </SidebarNavLink>
                            <SidebarNavLink to="/inquiries">Запитвания</SidebarNavLink>
                            <SidebarNavLink to="/orders">Поръчки</SidebarNavLink>
                            <SidebarNavLink to="/profile">Профил</SidebarNavLink>
                        </ListGroup>
                    ) : userRole === ROLE_ADMIN ? (
                        <ListGroup>
                            <SidebarNavLink to="/" activeOnlyWhenExact={true}>
                                Табло
                            </SidebarNavLink>
                            <SidebarNavLink to="/services">Услуги</SidebarNavLink>
                            <SidebarNavLink to="/partners">Партньори</SidebarNavLink>
                            <SidebarNavLink to="/inquiries">Запитвания</SidebarNavLink>
                            <SidebarNavLink to="/orders">Поръчки</SidebarNavLink>
                            <SidebarNavLink to="/users">Потребители</SidebarNavLink>
                        </ListGroup>
                    ) : null}
                </Col>
                <Col md={10} className="pl-0 mt-5 mt-md-0">
                    {userRole === ROLE_PARTNER ? (
                        <PartnerRoutes />
                    ) : userRole === ROLE_ADMIN ? (
                        <AdminRoutes />
                    ) : null}
                </Col>
            </Row>
        </Container>
    );
};
export default AuthContainer;
