import React from 'react';
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import styled from 'styled-components';

function SidebarNavLink({ to, children, activeOnlyWhenExact }) {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });
    const exactMatch = useRouteMatch({
        path: to,
        exact: true
    });

    const refreshIfCurrentRoute = (e, to) => {
        if (exactMatch) {
            e.preventDefault();
            history.go(0);
            if (location.search) {
                history.replace({ search: '' });
            }
            history.go(0);
        }
    };
    return (
        <StyledListGroupItem active={match}>
            <Link to={to} onClick={(e, to) => refreshIfCurrentRoute(e, to)}>
                {children}
            </Link>
        </StyledListGroupItem>
    );
}

export default SidebarNavLink;

const StyledListGroupItem = styled(ListGroup.Item)`
    &.active {
        a {
            color: #fff;
        }
    }
`;
