import React, { Suspense } from 'react';
import PrivateRoute from 'components/routes/PrivateRoute';
import { Redirect, Switch } from 'react-router-dom';

const DashboardPage = React.lazy(() => import('components/pages/admin/DashboardPage'));
const ServicesPage = React.lazy(() => import('components/pages/admin/ServicesPage'));

const PartnersPage = React.lazy(() => import('components/pages/admin/PartnersPage'));

const EditPartnersPage = React.lazy(() => import('components/pages/admin/EditPartnersPage'));

const UsersPage = React.lazy(() => import('components/pages/admin/User/UsersPage'));

const InquiriesPage = React.lazy(() => import('components/pages/admin/InquiriesPage'));

const SingleInquiryPage = React.lazy(() => import('components/pages/admin/SingleInquiryPage'));

const OrdersPage = React.lazy(() => import('components/pages/admin/OrdersPage'));

const SingleOrderPage = React.lazy(() => import('components/pages/admin/SingleOrderPage'));

const SingleUserPage = React.lazy(() => import('components/pages/admin/User/SingleUserPage'));

const SingleUserOrdersPage = React.lazy(() =>
    import('components/pages/admin/User/SingleUserOrdersPage')
);

const SingleUserInquiriesPage = React.lazy(() =>
    import('components/pages/admin/User/SingleUserInquiriesPage')
);

function AdminRoutes() {
    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                <PrivateRoute path="/" exact>
                    <DashboardPage />
                </PrivateRoute>
                <PrivateRoute path="/services">
                    <ServicesPage />
                </PrivateRoute>
                <PrivateRoute path="/inquiries" exact>
                    <InquiriesPage />
                </PrivateRoute>
                <PrivateRoute path="/inquiries/:id" exact>
                    <SingleInquiryPage />
                </PrivateRoute>
                <PrivateRoute path="/orders" exact>
                    <OrdersPage />
                </PrivateRoute>
                <PrivateRoute path="/orders/:id" exact>
                    <SingleOrderPage />
                </PrivateRoute>
                <PrivateRoute path="/partners" exact>
                    <PartnersPage />
                </PrivateRoute>
                <PrivateRoute path="/partners/:id/edit">
                    <EditPartnersPage />
                </PrivateRoute>
                <PrivateRoute exact path="/users">
                    <UsersPage />
                </PrivateRoute>
                <PrivateRoute exact path="/users/:id">
                    <SingleUserPage />
                </PrivateRoute>
                <PrivateRoute path="/users/:id/inquiries">
                    <SingleUserInquiriesPage />
                </PrivateRoute>
                <PrivateRoute path="/users/:id/orders">
                    <SingleUserOrdersPage />
                </PrivateRoute>
                <PrivateRoute>
                    <Redirect to="/" />
                </PrivateRoute>
            </Switch>
        </Suspense>
    );
}

export default AdminRoutes;
