import React, { useLayoutEffect, useRef, useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { login } from 'services/auth';
import useAuthState from 'custom-hooks/useAuthState';
import useToast from 'custom-hooks/useToast';
import { toastConstants, toastTypes } from 'constants/toastConstants';

function LoginPage() {
    const location = useLocation();
    const { setUser } = useAuthState();
    const { showToast } = useToast();
    const history = useHistory();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const emailInputEl = useRef(null);
    const passwordInputEl = useRef(null);

    useLayoutEffect(() => {
        setEmail(emailInputEl.current.value);
        setPassword(passwordInputEl.current.value);
    }, []);

    const handleClick = async event => {
        setIsLoading(true);
        try {
            const res = await login({ email, password });
            if (res) {
                const { username, roles } = res;
                setUser({ username, roles });
                if (location?.state?.from) {
                    history.push(location.state.from);
                } else {
                    history.push('/');
                }
                showToast(toastTypes.success, toastConstants.login.success);
            } else {
                throw new Error();
            }
        } catch {
            showToast(toastTypes.danger, toastConstants.login.error);
        }

        setIsLoading(false);
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm={6}>
                    <StyledForm>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Имейл адрес</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Имейл"
                                onChange={({ target }) => setEmail(target.value)}
                                ref={emailInputEl}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Парола</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Парола"
                                onChange={({ target }) => setPassword(target.value)}
                                ref={passwordInputEl}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" block onClick={handleClick}>
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Влез'}
                        </Button>
                    </StyledForm>
                </Col>
            </Row>
        </Container>
    );
}

const StyledForm = styled('div')`
    margin-top: 100px;
`;

export default LoginPage;
