export const formatDeliveryOption = (deliveryOption, date) => {
    switch (deliveryOption) {
        case 'URGENT':
            return 'Спешно';
        case 'WHEN_AVAILABLE':
            return 'При първа възможност';
        case 'DATE_PICKER':
            return 'Дата: ' + date;
        default:
            return null;
    }
};

export const formatNameAndPhone = contact => {
    const phone = contact?.phone ? `(${contact.phone})` : '';
    return ` ${contact?.firstName || ''} ${contact?.lastName || ''} ${phone}`;
};
